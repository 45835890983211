import { css } from 'astroturf';
import React from 'react';

const styles = css`
  .buttons {
    padding: 0rem 1rem 5rem;
    justify-content: center;
    align-items: center;
    gap: 2rem;
    flex-wrap: wrap;
    display: none;

    @media (min-width: 480px) {
      display: flex;
    }

    & a {
      display: block;
      background: #fff;
      color: #107db5;
      border: 2px solid;
      transition: 0.25s ease-in-out color;
      border-radius: 4rem;
      padding: 4px 10px;
      box-shadow: none;
      font-size: 1.125rem;
      white-space: nowrap;

      &::after,
      &::before {
        display: none;
      }

      &:hover,
      &:focus {
        color: #000;
      }
    }
  }
`;

function Buttons() {
  return (
    <div className={styles.buttons}>
      <a href="#website-builders">Website Builders</a>
      <a href="#ides">IDEs</a>
      <a href="#bug-reporting-tools">Bug Reporting Tools</a>
      {/* <a href="#analytics-tools">Analytics tools</a> */}
      <a href="#hand-off-tools">Hand-off tools</a>
      <a href="#devtools-extensions">Browser Extensions</a>
      <a href="#devtools-extensions">Devtools extensions</a>
      <a href="#browsers">Browsers</a>
    </div>
  );
}

export default Buttons;
