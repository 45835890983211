import React, { Component } from 'react';
import CTA from '../../components/CTA';
import PageHeader from '../../components/PageHeader';
import SEO from '../../components/SEO';
import Layout from '../../layout';

import Brow from '../../components/Brow';
import Buttons from './_Buttons';
import Block from './_IntegrationsBlock';

class Page extends Component {
  render() {
    return (
      <Layout>
        <SEO title="Integrations" keywords={['']} pathname={this.props.location.pathname} />
        <PageHeader>
          <Brow>Integrations</Brow>
          <h1>Use with your favorite tools</h1>
          <h2>Speed up your workflow with tools and services you already use and love.</h2>
        </PageHeader>
        <Buttons />
        <Block />
        <CTA />
      </Layout>
    );
  }
}

export default Page;
