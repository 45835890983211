import { css } from 'astroturf';
import { StaticImage } from 'gatsby-plugin-image';
import React from 'react';
import { ArrowRight } from 'react-bytesize-icons';

import Brow from '../../components/Brow';
import Link from '../../components/link';

import GoogleAnalytics from '../../../assets/images/integrations/googleanalytics.svg';
import Marker from '../../../assets/images/integrations/marker.svg';

import Avocode from '../../../assets/images/integrations/avocode.svg';
import Figma from '../../../assets/images/integrations/figma.svg';
import Invision from '../../../assets/images/integrations/invision.svg';
import Marvel from '../../../assets/images/integrations/marvel.svg';
import Sketch from '../../../assets/images/integrations/sketch.svg';
import Zeplin from '../../../assets/images/integrations/zeplin.svg';

import Brave from '../../../assets/images/integrations/brave.svg';
import Chrome from '../../../assets/images/integrations/chrome.svg';
import Edge from '../../../assets/images/integrations/edge.svg';
import Firefox from '../../../assets/images/integrations/firefox.svg';
import Opera from '../../../assets/images/integrations/opera.svg';
import Vivaldi from '../../../assets/images/integrations/vivaldi.svg';

const styles = css`
  .block {
    padding: 0rem 1rem 10rem;
    text-align: center;
    scroll-margin-top: 100px;

    & h2 {
      font-weight: 900;
      font-size: clamp(18px, 16px + 4vw, 44px);
      letter-spacing: -0.015em;
      line-height: 1.25;
      margin: auto;
      margin-bottom: 0.5rem;
      margin-top: 0;
      max-width: 60rem;
      color: #0a1530;
    }

    & p {
      font-size: calc(16px + 0.25vw);
      font-weight: 400;
      position: relative;
      max-width: 52rem;
      margin: 0 auto 1.5rem;
      line-height: 1.6;
      @media (min-width: 770px) {
        font-size: 1.17rem;
      }
    }
  }

  .items {
    width: 100%;
    display: flex;
    align-items: flex-start;
    justify-content: space-around;
    gap: 4rem;
    padding-top: 2rem;
    max-width: 52rem;
    flex-wrap: wrap;
    margin: 0 auto;

    &.browsers {
      gap: 1rem 4rem;
      max-width: 42rem;
      justify-content: center;
      margin-bottom: 2rem;
    }

    &.extensions {
      & > div {
        background: #fff;
        padding: 10px;
        border-radius: 10px;
        box-shadow:
          rgba(255, 255, 255, 0.15) 0px 1px 1px 0px inset,
          rgba(50, 50, 93, 0.3) 0px 25px 50px -10px,
          rgba(0, 0, 0, 0.5) 0px 15px 30px -15px,
          rgba(103, 178, 111, 0.3) -5px 5px 30px -5px;

        &.alpine {
          background: #30333f;
        }

        &.ember {
          background: #e25e42;
        }

        &.react {
          background: #1d1f1e;
        }
        &.preact {
          background: #683bb5;
        }

        & > div {
          display: block;
        }
      }

      & img {
        display: block;
      }
    }

    & *:last-child {
      justify-self: flex-start;
    }

    & *:only-child {
      justify-self: center;
      margin: auto;
    }
  }

  .item {
    display: flex;
    gap: 1rem;
    align-items: flex-start;
    justify-content: flex-start;

    text-align: left;
    width: 24rem;

    & .img {
      flex: 0 0 100px;
      text-align: right;
    }
    &.wide {
      width: 34rem;
      & img {
        flex-basis: 200px;
      }
    }

    & a {
      position: relative;
      display: block;
      pointer-events: all;
      margin: 0rem 0 0;
      font-size: 1.125rem;
      line-height: 1.25;
      padding: 0;
      display: inline-block;
      text-decoration: none;
      transition: all 0.25s ease-in-out;
      font-weight: 800;
      box-shadow: none;
      background: none;

      &::after {
        display: none;
        content: none;
      }

      & svg {
        transform: translateX(0);
        transition: 0.2s 0.12s ease-out;
        vertical-align: middle;
      }

      &:hocus {
        background: none;
        color: #000;
        text-decoration: none;

        & svg {
          transform: translateX(4px);
        }

        &::after {
          width: 0;
        }
      }
    }
  }
`;

function Block() {
  return (
    <>
      <div className={styles.block} id="website-builders">
        <h2>Website Builders</h2>
        <p>
          Previewing from these website builders automatically opens the pane sizes you've designed for, and updates the
          existing tabs in-place.
        </p>

        <div className={styles.items}>
          <div className={styles.item}>
            <div className={styles.img}>
              <StaticImage
                src="../../../assets/images/integrations/hype.png"
                alt=""
                objectFit="contain"
                placeholder="none"
                width={100}
                height={95}
                style={{ width: 100, height: 95 }}
                imgStyle={{ transition: 'none' }}
              />
            </div>
            <div className={styles.content}>
              <Brow>Tumult Hype</Brow>
              <p>Preview your Interactive web content and animations at the breakpoints you specified.</p>
              <Link to="/integrations/tumult-hype/">
                Learn more <ArrowRight width={16} height={16} strokeWidth="4px" id="" />
              </Link>
            </div>
          </div>
          <div className={styles.item}>
            <div className={styles.img}>
              <StaticImage
                src="../../../assets/images/integrations/whisk.png"
                alt=""
                objectFit="contain"
                placeholder="none"
                width={100}
                height={95}
                style={{ width: 100, height: 95 }}
                imgStyle={{ transition: 'none' }}
              />
            </div>
            <div className={styles.content}>
              <Brow>Tumult Whisk</Brow>
              <p>Preview your pages in multiple panes and reload when files change.</p>
              <Link to="/integrations/tumult-whisk/">
                Learn more <ArrowRight width={16} height={16} strokeWidth="4px" id="" />
              </Link>
            </div>
          </div>
        </div>
      </div>

      <div className={styles.block} id="ides">
        <h2>IDEs</h2>
        <p>
          Automatically open Polypane when you start your development server, update the existing panes in-place and
          debug remotely from your IDE.
        </p>

        <div className={styles.items}>
          <div className={styles.item}>
            <div className={styles.img}>
              <StaticImage
                src="../../../assets/images/integrations/webstorm.png"
                alt=""
                objectFit="contain"
                placeholder="none"
                width={100}
                height={95}
                style={{ width: 100, height: 95 }}
                imgStyle={{ transition: 'none' }}
              />
            </div>
            <div className={styles.content}>
              <Brow>JetBrains WebStorm</Brow>
              <p>Live reloading and Javascript debugging.</p>
              <Link to="/integrations/jetbrains-webstorm/">
                Learn more <ArrowRight width={16} height={16} strokeWidth="4px" id="" />
              </Link>
            </div>
          </div>
        </div>
      </div>

      <div className={styles.block} id="bug-reporting-tools">
        <h2>Bug reporting tools</h2>
        <p>
          Send bug reports directly from Polypane to speed up your QA using the integrated bug reporting tools directly
          in Polypane's panes.
        </p>

        <div className={styles.items}>
          <div className={styles.item}>
            <div className={styles.img}>
              <Marker width={90} height={60} />
            </div>
            <div className={styles.content}>
              <Brow>Marker.io</Brow>
              <p>Screenshot parts of your page and send it to your favorite bug tracker.</p>
              <Link to="/integrations/markerio/">
                Learn more <ArrowRight width={16} height={16} strokeWidth="4px" id="" />
              </Link>
            </div>
          </div>
        </div>
      </div>

      {/* <div className={styles.block} id="analytics-tools">
        <h2>Analytics tools</h2>
        <p>
          Use your analytics data to create Polypane workspaces so you design for the viewports your real visitors use.
          Import workspaces into Polypane with a single click.
        </p>

        <div className={styles.items}>
          <div className={[styles.item, styles.wide].join(' ')}>
            <div className={styles.img}>
              <GoogleAnalytics width={200} height={100} />
            </div>
            <div className={styles.content}>
              <Brow>Google Analytics</Brow>
              <p>
                Create a browser size workspace with the most used browser sizes based on your Analytics data from the
                past 30 days.
              </p>
              <Link to="/create-workspace/">
                Create workspace <ArrowRight width={16} height={16} strokeWidth="4px" id="" />
              </Link>
            </div>
          </div>
        </div>
      </div> */}

      <div className={styles.block} id="hand-off-tools">
        <h2>Hand-off tools</h2>
        <p>
          The Polypane Browse panel Lets you have all your favorite Hand-off tools open. Keep your design and specs
          visible while you're implementing your designs at lightspeed.
        </p>

        <div className={styles.items}>
          <Figma width={200} height={100} />
          <Marvel width={200} height={100} />
          <Sketch width={200} height={100} />
          <Avocode width={200} height={100} />
          <Zeplin width={200} height={100} />
        </div>
      </div>

      <div className={styles.block} id="devtools-extensions">
        <h2>Browser extensions</h2>
        <p>
          Install your favorite browser extensions, like Visbug, 1Password and more. They work just like you expect,
          across all your panes and the browse panel.
        </p>

        <div className={[styles.items, styles.browsers, styles.extensions].join(' ')}>
          <div data-tip="MetaMask">
            <StaticImage
              src="../../../assets/images/browserextensions/metamask.jpg"
              alt="MetaMask"
              objectFit="contain"
              placeholder="none"
              width={50}
              height={50}
              style={{ width: 50, height: 50 }}
              imgStyle={{ transition: 'none' }}
            />
          </div>
          <div data-tip="VisBug">
            <StaticImage
              src="../../../assets/images/browserextensions/visbug.jpg"
              alt="VisBug"
              objectFit="contain"
              placeholder="none"
              width={50}
              height={50}
              style={{ width: 50, height: 50 }}
              imgStyle={{ transition: 'none' }}
            />
          </div>
          <div data-tip="1Password">
            <StaticImage
              src="../../../assets/images/browserextensions/1password.png"
              alt="1Password"
              objectFit="contain"
              placeholder="none"
              width={50}
              height={50}
              style={{ width: 50, height: 50 }}
              imgStyle={{ transition: 'none' }}
            />
          </div>
          <div data-tip="Grammarly">
            <StaticImage
              src="../../../assets/images/browserextensions/grammarly.png"
              alt="Grammarly"
              objectFit="contain"
              placeholder="none"
              width={50}
              height={50}
              style={{ width: 50, height: 50 }}
              imgStyle={{ transition: 'none' }}
            />
          </div>
          <div data-tip="Simple Translate">
            <StaticImage
              src="../../../assets/images/browserextensions/simpletranslate.png"
              alt="Simple Translate"
              objectFit="contain"
              placeholder="none"
              width={50}
              height={50}
              style={{ width: 50, height: 50 }}
              imgStyle={{ transition: 'none' }}
            />
          </div>
          <div data-tip="Linguix">
            <StaticImage
              src="../../../assets/images/browserextensions/linguix.png"
              alt="Linguix"
              objectFit="contain"
              placeholder="none"
              width={50}
              height={50}
              style={{ width: 50, height: 50 }}
              imgStyle={{ transition: 'none' }}
            />
          </div>
          <div data-tip="LastPass">
            <StaticImage
              src="../../../assets/images/browserextensions/lastpass.png"
              alt="LastPass"
              objectFit="contain"
              placeholder="none"
              width={50}
              height={50}
              style={{ width: 50, height: 50 }}
              imgStyle={{ transition: 'none' }}
            />
          </div>
          <div data-tip="SnapFont">
            <StaticImage
              src="../../../assets/images/browserextensions/snapfont.png"
              alt="SnapFont"
              objectFit="contain"
              placeholder="none"
              width={50}
              height={50}
              style={{ width: 50, height: 50 }}
              imgStyle={{ transition: 'none' }}
            />
          </div>
          <div data-tip="Mate Translate">
            <StaticImage
              src="../../../assets/images/browserextensions/matetranslate.png"
              alt="Mate Translate"
              objectFit="contain"
              placeholder="none"
              width={50}
              height={50}
              style={{ width: 50, height: 50 }}
              imgStyle={{ transition: 'none' }}
            />
          </div>
          <div data-tip="Lorem Ipsum Generator">
            <StaticImage
              src="../../../assets/images/browserextensions/lorem.png"
              alt="Lorem Ipsum Generator"
              objectFit="contain"
              placeholder="none"
              width={50}
              height={50}
              style={{ width: 50, height: 50 }}
              imgStyle={{ transition: 'none' }}
            />
          </div>
        </div>
        <p>And more&hellip;</p>
      </div>

      <div className={styles.block} id="devtools-extensions">
        <h2>Devtools extensions</h2>
        <p>
          Polypane works with many devtools extensions. We have built-in support for the ones listed below, but you can
          install any listed in the Chrome extension store.
        </p>

        <div className={[styles.items, styles.browsers, styles.extensions].join(' ')}>
          <div data-tip="Alpinejs Devtools" className={styles.alpine}>
            <StaticImage
              src="../../../assets/images/browserextensions/alpinejs-devtools.jpg"
              alt="Alpinejs Devtools"
              objectFit="contain"
              placeholder="none"
              width={50}
              height={50}
              style={{ width: 50, height: 50 }}
              imgStyle={{ transition: 'none' }}
            />
          </div>
          <div data-tip="Angular DevTools">
            <StaticImage
              src="../../../assets/images/browserextensions/angular-devtools.jpg"
              alt="Angular devtools"
              objectFit="contain"
              placeholder="none"
              width={50}
              height={50}
              style={{ width: 50, height: 50 }}
              imgStyle={{ transition: 'none' }}
            />
          </div>
          <div data-tip="Apollo Client Developer Tools">
            <StaticImage
              src="../../../assets/images/browserextensions/apollo.jpg"
              alt="Apollo Client Developer Tools"
              objectFit="contain"
              placeholder="none"
              width={50}
              height={50}
              style={{ width: 50, height: 50 }}
              imgStyle={{ transition: 'none' }}
            />
          </div>
          <div data-tip="Backbone Debugger">
            <StaticImage
              src="../../../assets/images/browserextensions/backbonedebugger.jpg"
              alt="Backbone Debugger"
              objectFit="contain"
              placeholder="none"
              width={50}
              height={50}
              style={{ width: 50, height: 50 }}
              imgStyle={{ transition: 'none' }}
            />
          </div>
          <div data-tip="CSS Stacking Context">
            <StaticImage
              src="../../../assets/images/browserextensions/cssstackingcontext.jpg"
              alt="CSS Stacking Context"
              objectFit="contain"
              placeholder="none"
              width={50}
              height={50}
              style={{ width: 50, height: 50 }}
              imgStyle={{ transition: 'none' }}
            />
          </div>
          <div data-tip="DOM treemap">
            <StaticImage
              src="../../../assets/images/browserextensions/domtreemap.jpg"
              alt="DOM treemap"
              objectFit="contain"
              placeholder="none"
              width={50}
              height={50}
              style={{ width: 50, height: 50 }}
              imgStyle={{ transition: 'none' }}
            />
          </div>
          <div data-tip="Ember Inspector" className={styles.ember}>
            <StaticImage
              src="../../../assets/images/browserextensions/ember.jpg"
              alt="Ember Inspector"
              objectFit="contain"
              placeholder="none"
              width={50}
              height={50}
              style={{ width: 50, height: 50 }}
              imgStyle={{ transition: 'none' }}
            />
          </div>
          <div data-tip="WAVE Evaluation Tool">
            <StaticImage
              src="../../../assets/images/browserextensions/wave.jpg"
              alt="WAVE Evaluation Tool"
              objectFit="contain"
              placeholder="none"
              width={50}
              height={50}
              style={{ width: 50, height: 50 }}
              imgStyle={{ transition: 'none' }}
            />
          </div>
          <div data-tip="MobX Developers Tools">
            <StaticImage
              src="../../../assets/images/browserextensions/mobxdevtools.jpg"
              alt="MobX Developers Tools"
              objectFit="contain"
              placeholder="none"
              width={50}
              height={50}
              style={{ width: 50, height: 50 }}
              imgStyle={{ transition: 'none' }}
            />
          </div>
          <div data-tip="Preact DevTools" className={styles.preact}>
            <StaticImage
              src="../../../assets/images/browserextensions/preact.jpg"
              alt="Preact DevTools"
              objectFit="contain"
              placeholder="none"
              width={50}
              height={50}
              style={{ width: 50, height: 50 }}
              imgStyle={{ transition: 'none' }}
            />
          </div>
          <div data-tip="React Developer Tools" className={styles.react}>
            <StaticImage
              src="../../../assets/images/browserextensions/react.jpg"
              alt="React Developer Tools"
              objectFit="contain"
              placeholder="none"
              width={50}
              height={50}
              style={{ width: 50, height: 50 }}
              imgStyle={{ transition: 'none' }}
            />
          </div>
          <div data-tip="Redux DevTools">
            <StaticImage
              src="../../../assets/images/browserextensions/redux.jpg"
              alt="Redux DevTools"
              objectFit="contain"
              placeholder="none"
              width={50}
              height={50}
              style={{ width: 50, height: 50 }}
              imgStyle={{ transition: 'none' }}
            />
          </div>
          <div data-tip="Svelte Developer Tools">
            <StaticImage
              src="../../../assets/images/browserextensions/svelte.jpg"
              alt="Svelte Developer Tools"
              objectFit="contain"
              placeholder="none"
              width={50}
              height={50}
              style={{ width: 50, height: 50 }}
              imgStyle={{ transition: 'none' }}
            />
          </div>
          <div data-tip="Vue.js devtools">
            <StaticImage
              src="../../../assets/images/browserextensions/vue.jpg"
              alt="Vue.js devtools"
              objectFit="contain"
              placeholder="none"
              width={50}
              height={50}
              style={{ width: 50, height: 50 }}
              imgStyle={{ transition: 'none' }}
            />
          </div>
          <div data-tip="XState DevTools">
            <StaticImage
              src="../../../assets/images/browserextensions/xstate.jpg"
              alt="XState DevTools"
              objectFit="contain"
              placeholder="none"
              width={50}
              height={50}
              style={{ width: 50, height: 50 }}
              imgStyle={{ transition: 'none' }}
            />
          </div>
        </div>
        <p>
          &hellip; and more. <Link to="/docs/browser-extensions/">Learn more about Browser extensions in Polypane</Link>
        </p>
      </div>

      <div className={styles.block} id="browsers">
        <h2>Browsers</h2>
        <p>
          No matter what your regular "browsing" browser is, The Polypane Browser extension lets you open the current
          URL in Polypane with a single click.
        </p>

        <div className={[styles.items, styles.browsers].join(' ')}>
          <Brave width={100} height={100} />
          <Chrome width={100} height={100} />
          <Edge width={100} height={100} />
          <Firefox width={100} height={100} />
          <Opera width={100} height={100} />
          <StaticImage
            src="../../../assets/images/integrations/safari.png"
            alt=""
            objectFit="contain"
            placeholder="none"
            width={100}
            height={100}
            style={{ width: 100, height: 100 }}
            imgStyle={{ transition: 'none' }}
          />
          <Vivaldi width={100} height={100} />
        </div>
        <p>
          <Link to="/download/#extensions">Download extensions</Link>
        </p>
      </div>

      <div className={styles.block} id="missing">
        <h2>Missing an integration?</h2>
        <p>
          Let us know which integrations you'd like to see! <Link to="/support/">Reach out to us</Link>.
        </p>
      </div>
    </>
  );
}

export default Block;
